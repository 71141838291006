import { EventDispatcher } from "./events/EventDispatcher";

export class SceneEvent extends EventDispatcher
{
	public static WINDOW_RESIZE: string = "WINDOW_RESIZE";
	public static JSON_LOADED: string = "JSON_LOADED";
	public static COMPLETE: string = "COMPLETE";

	private static _instance: SceneEvent;

	public static getInstance(): SceneEvent
	{
		if(!this._instance)
		{
			this._instance = new SceneEvent(SceneEvent.getInstance);
		}

		return this._instance;
	}

	constructor(caller: Function)
	{
		if(caller == SceneEvent.getInstance)
		{
			super();
		}
		else if(SceneEvent._instance)
		{
			throw new Error("既にインスタンスが存在するためエラー。");
		}
		else
		{
			throw new Error("コンストラクタの引数が不正な為エラー。");
		}
	}
}