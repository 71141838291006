import * as THREE from "three";
import GUI from 'lil-gui';

export class WebglData
{
	public static gui: GUI | null;
	
	public static perlinTexture: THREE.Texture

	public static color: THREE.Color[] = 
	[
		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),

		new THREE.Color(0xCA1A1D),
		new THREE.Color(0xA600FF),
		new THREE.Color(0x0020C5),
		new THREE.Color(0x00C8FF),
		new THREE.Color(0x0CB900),
		new THREE.Color(0xFF8400),
		new THREE.Color(0xA3004F),
		new THREE.Color(0xDC0084),
	]

}