varying vec3 vColor;
varying float vAlpha;

void main()
{
    float strength = distance(gl_PointCoord, vec2(0.5));
    strength = step(0.5, strength);
    strength = 1.0 - strength;

    vec3 color = vColor;
	if(strength==0.0)
	{
		discard;
	}
	else
	{
    	gl_FragColor = vec4(color, vAlpha);
	}

    #include <colorspace_fragment>

}