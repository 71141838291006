export class DataStorage
{
	// public static PC_SIZE: number[] = [1280, 564];
	// public static SP_SIZE: number[] = [376, 440];
	public static PC_SIZE: number[] = [1280, 833];
	public static SP_SIZE: number[] = [376, 667];

	public static init_w: number;
	public static init_h: number;
	public static window_w: number;							//ウィンドウ幅
	public static window_h: number;							//ウィンドウ高さ
	public static scrollTop: number = 0;
	public static scrollLeft: number = 0;

	public static isSP: boolean;							//スマホかどうか
	public static isDebug: boolean;
	public static isSet: boolean;
	public static isChange: boolean;

	public static device: string = "";
	public static size: number[] = [0, 0];
	public static scale: number = 0;

}