import { Event } from "./events/EventDispatcher";
import { SceneEvent } from "./SceneEvent";
import { DataStorage } from "./DataStorage";
import { CanvasView } from "./webgl/CanvasView";
import { ParticleData } from "./webgl/ParticleData"
import { gsap } from "gsap"

declare var typoLoaded: any;

declare global {
  interface Window {
    setTypoPC: () => void;
    setTypoSP: () => void;
    changeTypoPC: () => void;
    changeTypoSP: () => void;
  }
}
window.setTypoPC = function() {
	typoApps.setTypoPC();
};
window.setTypoSP = function() {
	typoApps.setTypoSP();
};
window.changeTypoPC = function() {
	typoApps.changeTypoPC();
};
window.changeTypoSP = function() {
	typoApps.changeTypoSP();
};

class TypoApps
{
	public constructor()
	{
		document.addEventListener('DOMContentLoaded', this.ready.bind(this));
		window.addEventListener('load', this.load.bind(this));
		window.addEventListener('resize', this.resizing.bind(this));

	}
	public ready(): void
	{
		if(navigator.userAgent.indexOf('iPhone')>0 || navigator.userAgent.indexOf('iPod')>0 || navigator.userAgent.indexOf('iPad')>0 || navigator.userAgent.indexOf('Android')>0)
		{
			//SP
			DataStorage.isSP = true;
			document.documentElement.classList.add("_sp");
			document.body.classList.add("_SP");
		}
		else
		{
			//PC
			document.documentElement.classList.add("_pc");
			document.body.classList.add("_PC");
		}

		//ハッシュを調べる
		if(location.hash=="#debug")
		{
			DataStorage.isDebug = true;
		}

		//立ち上げ時サイズ
		DataStorage.init_w = document.documentElement.clientWidth;
		DataStorage.init_h = document.documentElement.clientHeight;
		// DataStorage.init_w = window.innerWidth;
		// DataStorage.init_h = window.innerHeight;

		this.resizing();

	}
	public load(): void
	{

	}
	public setTypoPC(): void
	{
		if(!DataStorage.isSet)
		{
			DataStorage.isSet = true;

			DataStorage.device = "pc";
			DataStorage.size = DataStorage.PC_SIZE;
	
			this.jsonLoad();
		}
	}
	public setTypoSP(): void
	{
		if(!DataStorage.isSet)
		{
			DataStorage.isSet = true;

			DataStorage.device = "sp";
			DataStorage.size = DataStorage.SP_SIZE;
	
			this.jsonLoad();
		}
	}
	public changeTypoPC(): void
	{
		if(DataStorage.isSet)
		{
			DataStorage.isChange = true;

			gsap.killTweensOf(document.querySelector('#kv ._pc'))
			gsap.killTweensOf(document.querySelector('#kv ._sp'))
			gsap.set(document.querySelector('#kv ._pc'), {css:{opacity:1, display:"block"}})
			gsap.set(document.querySelector('#kv ._sp'), {css:{opacity:0, display:"none"}})
		}
	}
	public changeTypoSP(): void
	{
		if(DataStorage.isSet)
		{
			DataStorage.isChange = true;

			gsap.killTweensOf(document.querySelector('#kv ._pc'))
			gsap.killTweensOf(document.querySelector('#kv ._sp'))
			gsap.set(document.querySelector('#kv ._pc'), {css:{opacity:0, display:"none"}})
			gsap.set(document.querySelector('#kv ._sp'), {css:{opacity:1, display:"block"}})
		}
	}
	//JSON読み込み
	public jsonLoad(): void
	{
		SceneEvent.getInstance().addEventListener(SceneEvent.JSON_LOADED, this.jsonLoaded.bind(this));

		var _httpObj:XMLHttpRequest = new XMLHttpRequest();
		_httpObj.open("get", "typo-assets/data/ParticleData_"+DataStorage.device+".json?"+new Date().getTime(), true);
		_httpObj.onload = function()
		{
			// console.log("Apps.jsonLoad.onload:"+this.status);
			if(this.status==200)
			{
				ParticleData.json = JSON.parse(this.responseText);

				typoLoaded();

				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.JSON_LOADED));
			}
			else
			{
				// console.log("Apps.jsonLoad.onerror");
			}
		};
		_httpObj.onerror = function()
		{
			// console.log("Apps.jsonLoad.onerror");
		};
		_httpObj.send(null);

	}
	public jsonLoaded(): void
	{
		new CanvasView();

	}
	public resizing(): void
	{
		DataStorage.window_w = window.innerWidth;
		DataStorage.window_h = window.innerHeight;

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.WINDOW_RESIZE));

	}
}
let typoApps = new TypoApps();
