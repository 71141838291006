import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage"
import { ParticleData } from "./ParticleData"
import { WebglData } from "./WebglData"
import vertexShader from "../shaders/particle.vert"
import fragmentShader from "../shaders/particle.frag"
import * as THREE from "three"
import { gsap, Power1, Power3 } from "gsap"

export class Particles
{
	public container: THREE.Object3D
	
	public id: number
	public data: any

	private parameters: any = {}
	private geometry: THREE.BufferGeometry
	private material: THREE.ShaderMaterial
	private points: THREE.Points

	private resizeBindThis: Function;

	constructor(id:number)
	{
		this.id = id
		this.data = ParticleData.json.data[this.id]
		
		this.container = new THREE.Object3D()

		this.init()

	}
	public init(): void
	{
		this.parameters.count = this.data.length
		if(DataStorage.device=="pc")
		{
			this.parameters.size = 2.0;
		}
		else
		{
			this.parameters.size = 2.6;
		}
		this.parameters.resolution = window.innerWidth * 1/window.innerHeight * DataStorage.scale
		this.parameters.color = WebglData.color[this.id]
		this.parameters.animationX = 1
		this.parameters.animationY = 1
		this.parameters.animationZ = 1
		this.parameters.animationAlpha = 0
		this.parameters.animationSize = 1
		this.parameters.animationColor = 1
		this.parameters.animationNoise = 1
		this.parameters.animationInfluence = 0

		this.resizeBindThis = this.resize.bind(this)
		SceneEvent.getInstance().addEventListener(SceneEvent.WINDOW_RESIZE, this.resizeBindThis)
		
		this.generate()

	}
	public generate(): void
	{
		this.geometry = new THREE.BufferGeometry()
		
		const positions = new Float32Array(this.parameters.count * 3)
		const colors = new Float32Array(this.parameters.count * 3)
		const aUv = new Float32Array(this.parameters.count * 2)
		const aRandom = new Float32Array(this.parameters.count * 3)
		const aAlpha = new Float32Array(this.parameters.count)
		const aSize = new Float32Array(this.parameters.count)
		const aActive = new Float32Array(this.parameters.count)
	
		for(let i = 0; i < this.parameters.count; i++)
		{
			const i2 = i * 2
			const i3 = i * 3

			positions[i3    ] = (this.data[i][0] - DataStorage.size[0]/2)
			positions[i3 + 1] = (-this.data[i][1] + DataStorage.size[1]/2)
			positions[i3 + 2] = 0

			colors[i3    ] = this.data[i][2][0]
			colors[i3 + 1] = this.data[i][2][1]
			colors[i3 + 2] = this.data[i][2][2]

			aUv[i2    ] = this.data[i][0] / DataStorage.size[0]
			aUv[i2 + 1] = this.data[i][1] / DataStorage.size[1]

			aRandom[i3    ] = Math.random() * 6 - 3
			aRandom[i3 + 1] = Math.random() * 6 - 3
			aRandom[i3 + 2] = Math.random() * 6 - 3

			aAlpha[i] = this.data[i][2][3]/255

			const size = Math.random() * 5.1
			aSize[i] = size * size

			aActive[i] = (Math.random()>0.03) ? 0 : 1
		}
	
		this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
		this.geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
		this.geometry.setAttribute('aUv', new THREE.BufferAttribute(aUv, 2))
		this.geometry.setAttribute('aRandom', new THREE.BufferAttribute(aRandom, 3))
		this.geometry.setAttribute('aAlpha', new THREE.BufferAttribute(aAlpha, 1))
		this.geometry.setAttribute('aSize', new THREE.BufferAttribute(aSize, 1))
		this.geometry.setAttribute('aActive', new THREE.BufferAttribute(aActive, 1))

		this.material = new THREE.ShaderMaterial({
			vertexColors: true,
			uniforms:
			{
				uSize: { value: this.parameters.size * Math.min(window.devicePixelRatio, 2) },
				uResolution: { value: this.parameters.resolution },
				uColor: { value: this.parameters.color },
				uNoiseTextur: { value: WebglData.perlinTexture },
				uAnimationX: { value: this.parameters.animationX },
				uAnimationY: { value: this.parameters.animationY },
				uAnimationZ: { value: this.parameters.animationZ },
				uAnimationAlpha: { value: this.parameters.animationAlpha },
				uAnimationSize: { value: this.parameters.animationSize },
				uAnimationColor: { value: this.parameters.animationColor },
				uAnimationNoise: { value: this.parameters.animationNoise },
				uAnimationInfluence: { value: this.parameters.animationInfluence }
			},    
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			depthTest: true,
			transparent: true,
		})

		this.points = new THREE.Points(this.geometry, this.material)
		this.container.add(this.points)
		
		this.animation()
	}
	public animation(): void
	{
		let delay = 0.036*this.id
		gsap.to(this.parameters, 1.2, {animationX:0, ease:Power3.easeOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationX.value = this.parameters.animationX}})
		gsap.to(this.parameters, 1.2, {animationY:0, ease:Power3.easeOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationY.value = this.parameters.animationY}})
		gsap.to(this.parameters, 1.2, {animationZ:0, ease:Power3.easeOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationZ.value = this.parameters.animationZ}})
		gsap.to(this.parameters, 0.6, {animationAlpha:1, ease:Power1.easeInOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationAlpha.value = this.parameters.animationAlpha}})
		gsap.to(this.parameters, 1.2, {animationSize:0, ease:Power1.easeInOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationSize.value = this.parameters.animationSize}})
		gsap.to(this.parameters, 0.6, {animationColor:0, ease:Power1.easeInOut, delay:delay+1.2, onUpdate:()=>{this.material.uniforms.uAnimationColor.value = this.parameters.animationColor}})
		gsap.to(this.parameters, 1.2, {animationNoise:0, ease:Power3.easeOut, delay:delay, onUpdate:()=>{this.material.uniforms.uAnimationNoise.value = this.parameters.animationNoise}})
		gsap.to(this.parameters, 0.1, {animationInfluence:1, ease:Power1.easeInOut, delay:delay+1.2*0.3, onUpdate:()=>{this.material.uniforms.uAnimationInfluence.value = this.parameters.animationInfluence}})

	}
	public resize(): void
	{
		this.parameters.resolution = window.innerWidth * 1/window.innerHeight * DataStorage.scale
		this.material.uniforms.uResolution.value = this.parameters.resolution

	}
	public remove(): void
	{
		SceneEvent.getInstance().removeEventListener(SceneEvent.WINDOW_RESIZE, this.resizeBindThis)
		
		gsap.killTweensOf(this.parameters)

		this.container.remove(this.points)
		this.points = null

		this.geometry.dispose()
		this.material.dispose()
		
		this.geometry = null
		this.material = null
		this.container = null
	}
}