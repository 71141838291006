import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from "../DataStorage"
import { Particles } from "./Particles";
import { ParticleData } from "./ParticleData"
import { WebglData } from "./WebglData"
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { gsap, Power1 } from "gsap"

export class CanvasView
{
	private scene: THREE.Scene;
	private camera: THREE.PerspectiveCamera;
	private renderer: THREE.WebGLRenderer;

	private particles: Particles[] = [];

	private isActive: boolean = false;

	private resizeBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.initThree();

		this.resizeBindThis = this.resize.bind(this)
		SceneEvent.getInstance().addEventListener(SceneEvent.WINDOW_RESIZE, this.resizeBindThis)
		this.resize();

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.COMPLETE, this.removeBindThis);

		this.rendering();

	}
	public initThree(): void
	{
		this.isActive = true;

		this.scene = new THREE.Scene();

		this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100)
		this.camera.position.z = 3

        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: false });
		this.renderer.setSize(window.innerWidth, window.innerHeight)
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
		this.renderer.setClearColor(0xffffff, 1);
		document.querySelector("#typo")!.appendChild(this.renderer.domElement);

		const textureLoader = new THREE.TextureLoader()
		WebglData.perlinTexture = textureLoader.load(
			'typo-assets/img/8.png',
			(texture)=>{
				this.initParticles();
			}
		)

		// new OrbitControls(this.camera, this.renderer.domElement);

	}
	public initParticles(): void
	{
		for(let i = 0; i < ParticleData.json.data.length; i++)
		{
			this.particles[i] = new Particles(i);
			this.scene.add(this.particles[i].container);
		}

		if(!DataStorage.isChange)
		{
			gsap.killTweensOf(document.querySelector('#kv ._'+DataStorage.device))
			gsap.to(document.querySelector('#kv ._'+DataStorage.device), 0.6, {css:{opacity:1, display:"block"}, ease:Power1.easeInOut, delay:1.5, onComplete:()=>
			{
				SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.COMPLETE));
			}})
		}
	}
	public rendering(): void
	{
		if(this.isActive)
		{
			this.renderer.render(this.scene, this.camera);
	
			requestAnimationFrame(this.rendering.bind(this));
		}
	}
	public resize(): void
	{
		if (!this.renderer) return;
		
		this.camera.aspect = window.innerWidth / window.innerHeight;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(window.innerWidth, window.innerHeight);

		if(DataStorage.device=="pc")
		{
			if(window.innerWidth/window.innerHeight<DataStorage.PC_SIZE[0]/DataStorage.PC_SIZE[1])
			{
				DataStorage.scale = 0.719;
			}
			else
			{
				// DataStorage.scale = window.innerHeight/window.innerWidth*1.63;
				DataStorage.scale = window.innerHeight/window.innerWidth*1.105;
			}
			
			if(window.innerWidth>1600)
			{
				if(1600/window.innerHeight<DataStorage.PC_SIZE[0]/DataStorage.PC_SIZE[1])
				{
					DataStorage.scale = 0.719/window.innerWidth*1600;
				}
			}
		}
		else if(DataStorage.device=="sp")
		{
			if(window.innerWidth/window.innerHeight<DataStorage.SP_SIZE[0]/DataStorage.SP_SIZE[1])
			{
				DataStorage.scale = 2.45;
			}
			else
			{
				// DataStorage.scale = window.innerHeight/window.innerWidth*2.085;
				DataStorage.scale = window.innerHeight/window.innerWidth*1.38;
			}
		}
	}
	public remove(): void
	{
		this.isActive = false;

		SceneEvent.getInstance().removeEventListener(SceneEvent.COMPLETE, this.removeBindThis);

		this.renderer.domElement.remove();
		this.renderer.dispose();

		this.scene.remove(...this.scene.children);

		this.camera.remove();
		this.camera = null;

		this.renderer = null;
		this.scene = null;

		for(let i = 0; i < this.particles.length; i++)
		{
			this.particles[i].remove();
		}
		this.particles = null;
		
	}
}