uniform float uSize;
uniform float uResolution;
uniform vec3 uColor;
uniform sampler2D uNoiseTextur;
uniform float uAnimationX;
uniform float uAnimationY;
uniform float uAnimationZ;
uniform float uAnimationAlpha;
uniform float uAnimationSize;
uniform float uAnimationColor;
uniform float uAnimationNoise;
uniform float uAnimationInfluence;

attribute vec2 aUv;
attribute vec3 aRandom;
attribute float aAlpha;
attribute float aSize;
attribute float aActive;

varying vec3 vColor;
varying float vAlpha;

void main()
{
    vec4 modelPosition = modelMatrix * vec4(position * uResolution * 0.005, 1.0);

    vec4 _noise = texture2D(uNoiseTextur, aUv) * uResolution;
    modelPosition.x += (_noise.x - 0.5) * uAnimationNoise * 5.1;
    modelPosition.y += (_noise.y - 0.5) * uAnimationNoise * 5.1;
    modelPosition.z += (_noise.x - 0.5) * uAnimationNoise * 5.1;

    modelPosition.x += aRandom.x * uAnimationX * _noise.x;
    modelPosition.y += aRandom.y * uAnimationY * _noise.y;
    modelPosition.z += aRandom.z * uAnimationZ * _noise.z;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    vAlpha = aAlpha * uAnimationAlpha;
    vColor = mix(color, uColor, uAnimationColor);

    gl_Position = projectedPosition;
    gl_PointSize = uSize * uResolution;
    gl_PointSize *= (1.0 / -viewPosition.z) * 3.6;

    if(aActive==1.0)
    {
        gl_PointSize += aSize * uAnimationSize;
    }
    else
    {
        gl_PointSize *= uAnimationInfluence;
        vAlpha *= uAnimationInfluence;
    }
}